import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import './HomePage.css';
import '../Header/Header.css';
import Sidebar from '../Sidebar/SideBar'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';

const HomePage = ( ) => {
    const location =useLocation()

    
const dispatchvalue = useDispatch();

    const  isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen )

    const  toggled = useSelector(state=>state.userRecord?.toggled )

  return (
    <>

    <div className="app-container" onClick={(e) => e.stopPropagation()}>
      {location.pathname !== '/Preview' && (
        <Header />
      )}

      <div className='main-content'>

        {location.pathname !== '/Preview' && (
          <>
            <div className= {toggled?'sidebar_toggle1' :'sidebar_toggle2'}>
            
                <Sidebar />
            </div>
            
             
          </>
        )}

        <div className={isSidebarOpen ? 'content' : "content2"} onClick={(() => dispatchvalue({type:'isSidebarOpen',value:false}))}>
            <Outlet/>
         

        </div>
      </div>
      {location.pathname !== '/Preview' && (
        <div className='main-footer'>
          <Footer />
        </div>
      )}

    </div>
  
  </>
  )
}

export default HomePage