import React from 'react'


function AccountPayable() {
  return (
    <div className="Selected-table-container">
    <table className="selected-medicine-table2">
      <thead>
        <tr>
          <th>
          <div className="tfdrtdf6"><div className="twsrtw3">
                <button className="sxsxe" onClick={'addPayment'}> </button>
              </div>Serial No </div></th>
          <th>Payment Method</th>
          <th>Description</th>
          <th>Account No</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {'selectedPayment'.map((paymentInfo, index) => (
          <tr key={index}>
            <td>
            {paymentInfo.SerialNo}
              </td>
            <td>{paymentInfo.PaymentMethod}</td>
            <td>{paymentInfo.Description}</td>
            <td>{paymentInfo.AccountNo}</td>
            <td>{paymentInfo.Amount}</td>
          </tr>
        ))}
      </tbody>
    </table>

  </div>
  )
}

export default AccountPayable;


